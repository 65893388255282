#topbar-inner {
    background-color: color('grey', 'darken-3');
    font-size: 0.8em;

    @media #{$medium-and-down} {
      .hidden-xs {
          display: none;
      }
    }

    #titleBar > a {
        color: color('grey', 'lighten-6');
        display: block;
        font-family: 'Ek Mukta',Arial,Helvetica,sans-serif;
        font-weight: 200;
        letter-spacing: 2px;
        font-variant-caps: small-caps;

        @media #{$medium-and-up} {
            font-size: 18px;
        }

        @media #{$medium-and-down} {
            text-align: center;
        }
    }
}
